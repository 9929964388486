import mobileMenuStyle from "../styles/components/MobileMenu.module.css";

import koboLogo from "../asset/logo/logo_white.svg";
import beanzImage from "../asset/mascotte/Beanz_skate 1.svg";
import beanzImagePNG from "../asset/mascotte/Beanz_skate 1.png";
import closeIcon from "../asset/menu/close-menu.svg";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { workshop_termsOfService } from "../data/termsOfService";
import { shop_termsOfService } from "../data/termsOfService";
import { isDeviceIOS } from "../data/utils";

const MobileMenu = ({ open, closeMobileMenu }) => {
  const location = useLocation();

  const [openMenu, setOpenMenu] = useState(false);
  const [screen, setScreen] = useState("navigation");

  useEffect(() => {
    setOpenMenu(open);
  }, [open]);

  const closeButtonClicked = () => {
    if (screen === "tos") {
      setScreen("navigation");
      return;
    }

    closeMobileMenu();
  };

  const getTermsOfService = () => {
    if (location.pathname === "/workshop") {
      return workshop_termsOfService;
    } else if (location.pathname === "/shop") {
      return shop_termsOfService;
    } else {
      return [];
    }
  };

  return (
    <div
      className={`${mobileMenuStyle.mobileMenu} ${
        openMenu
          ? mobileMenuStyle.mobileMenu__opened
          : mobileMenuStyle.mobileMenu__closed
      }`}
    >
      <div className={mobileMenuStyle.mobileMenu__top}>
        <img
          src={closeIcon}
          onClick={closeButtonClicked}
          alt="close icon"
          className="w-[2.3rem] cursor-pointer self-end"
        />
        <img
          src={koboLogo}
          alt="logo"
          className="logo h-[3.3rem] cursor-pointer m-auto flex-1"
        />
        <div className="w-[2.3rem] h-1"></div>
      </div>
      <div
        className={`${mobileMenuStyle.mobileMenu__content} ${mobileMenuStyle[screen]}`}
      >
        <div className={mobileMenuStyle.mobileMenu__navigationMenu}>
          <ul>
            <li className={openMenu ? "animate" : ""}>
              <Link
                to={"/workshop"}
                className={`${
                  location.pathname === "/workshop" ? "underline" : ""
                }`}
              >
                Workshop
              </Link>
            </li>
            <li>
              <Link
                to={"/shop"}
                className={`${
                  location.pathname === "/shop" ? "underline" : ""
                }`}
              >
                Shop
              </Link>
            </li>
            <li>
              <img
                src={isDeviceIOS ? beanzImagePNG : beanzImage}
                // src={beanzImage}
                alt="beanz skate"
                className="w-[25.4rem]"
              />
            </li>
            <li onClick={() => setScreen("tos")} className="cursor-pointer">
              Terms of service
            </li>
            <li>
              {" "}
              <a
                href="https://twitter.com/messages/compose?recipient_id=1711767105664581632"
                target="_blank"
                rel="noreferrer"
              >
                Support
              </a>
            </li>
            <li>
              <a href="https://twitter.com/kobo_craft" target="blank">
                Twitter
              </a>
            </li>
          </ul>
          <p className={mobileMenuStyle.mobileMenu__copyright}>
            KŌBŌ™ All rights reserved.
          </p>
        </div>
        <div className={mobileMenuStyle.mobileMenu__tosMenu}>
          <div className={mobileMenuStyle.mobileMenu__tosMenuWrapper}>
            {getTermsOfService().map((termObject, index) => {
              return (
                <div className="pb-[3rem]" key={index}>
                  <h2 id={termObject.id}>{termObject.title}</h2>
                  {termObject.description &&
                    termObject.description.map((desc, index) => {
                      if (desc.includes("//{")) {
                        const jsonStart = desc.indexOf("//{");
                        const jsonEnd = desc
                          .substring(jsonStart + 2)
                          .indexOf("}//");
                        const json = desc.substring(
                          jsonStart + 2,
                          jsonStart + jsonEnd + 3
                        );
                        const preJson = desc.substring(0, jsonStart);
                        const postJson = desc.substring(
                          jsonStart + jsonEnd + 5
                        );
                        const textLink = JSON.parse(json);
                        return (
                          <p key={index} className="py-2 font-thin">
                            {preJson}
                            <a
                              href={textLink.link}
                              target="_blank"
                              rel="noreferrer"
                              className="underline"
                            >
                              {textLink.title}
                            </a>
                            {postJson}
                          </p>
                        );
                      } else {
                        return (
                          <p key={index} className="py-2 font-thin">
                            {desc}
                          </p>
                        );
                      }
                    })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
    //   <div className={shopStyle.shop__mobileMenu}>
    //   <Link to="/shop" className={shopStyle.shop__mobileMenuItem}>
    //     Shop
    //   </Link>
    //   <Link to="/workshop" className={shopStyle.shop__mobileMenuItem}>
    //     Workshop
    //   </Link>
    // </div>
  );
};

export default MobileMenu;
