import { useEffect, useState } from "react";
import { checkTrait } from "../data/skateTraits";
import ethSymbol from "../asset/ETH.svg";
import copy_default from "../asset/copy/copy_1.svg";
import copy_hover from "../asset/copy/copy_2.svg";
import copy_click from "../asset/copy/copy_3.svg";

const servercall = `${process.env.REACT_APP_SERVER_BASE_URL}`;
const wallet = "0x4f5dC42B155eF3B720AaBE5fa843222AeFEe841d";

export const Order = ({ data }) => {
  const [orderForm, setOrderForm] = useState({});
  const [errors, setErrors] = useState({});
  const [basePricing, setBasePricing] = useState({});
  const [totalPricing, setTotalPricing] = useState(0);
  const [salesPerc, setSalesPerc] = useState(0);
  const [saleName, setSaleName] = useState("");

  const [promo, setPromo] = useState(false);
  const [trait, setTrait] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [promoButtonClicked, setPromoButtonClicked] = useState(false);

  const [actionCopy, setActionCopy] = useState("leave");

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setTrait(checkTrait(data?.collection, data?.idNft, data?.companionId));

    const promiseCalls = async () => {
      const data = await checkPrice();
      setBasePricing(data.pricing);

      const sales = await checkSales();
      if (sales.data.salesActive) {
        setSaleName(sales.data.saleName);
        setSalesPerc(sales.data.salesPercentage);
      }
    };

    promiseCalls();
  }, []);

  useEffect(() => {
    let newPricing = basePricing;

    if (salesPerc) {
      Object.keys(newPricing).forEach((key) => {
        newPricing[key] *= 1 - salesPerc / 100;
      });
    } else if (promo || trait) {
      Object.keys(newPricing).forEach((key) => {
        newPricing[key] *= 0.9;
      });
    }

    setTotalPricing(newPricing);
  }, [promo, trait, salesPerc, basePricing]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setOrderForm((values) => ({ ...values, [name]: value }));
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const errors = validateForm(orderForm);
    setErrors(errors);

    if (!submitting && Object.keys(errors).length === 0) {
      setSubmitting(true);

      const { collection, idNft, companion, companionId, selfie, image } = data;

      const requestBody = {
        email: orderForm.email,
        collectorProfile: orderForm.collector,
        firstName: orderForm.firstName,
        lastName: orderForm.lastName,
        address: `${orderForm.address}${
          orderForm.company ? " " + orderForm.company : ""
        }`,
        zipCode: orderForm.postcode,
        city: orderForm.town,
        country: orderForm.country,
        skateTrait: trait,
        price: basePricing,
        totalPrice: totalPricing,
        promoCode: promo,
        salePerc: salesPerc,
        thxId: orderForm.transactionId,
        image: image,
        nftCollection: collection,
        idNft: idNft,
        companion: companion,
        companionId: companionId,
        selfie: selfie,
        phoneNumber: orderForm.phone,
      };

      await confirmOrder(requestBody);
    }
  }

  const validateForm = (form) => {
    let errors = {};

    if (!form.collector?.trim()) {
      errors.collector = "Collector ID is required";
    }
    if (!form.email?.trim()) {
      errors.email = "Email is required";
    }
    if (!form.firstName?.trim()) {
      errors.firstName = "First Name is required";
    }
    if (!form.lastName?.trim()) {
      errors.lastName = "Last Name is required";
    }
    if (!form.address?.trim()) {
      errors.address = "Address is required";
    }
    if (!form.town?.trim()) {
      errors.town = "Town is required";
    }
    if (!form.postcode?.trim()) {
      errors.postcode = "Postcode is required";
    }
    if (!form.country?.trim()) {
      errors.country = "Country is required";
    }
    // vecchia validazione per il numero di telefono
    // !/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(form.phone)
    if (!form.phone?.trim()) {
      errors.phone = "Phone is required";
    }
    if (!form.transactionId?.trim()) {
      errors.transactionId = "Transaction ID is required";
    }
    if (!form.disclaimer) {
      errors.disclaimer = "Required";
    }

    return errors;
  };

  const confirmOrder = async (requestBody) => {
    try {
      const response = await fetch(`${servercall}create`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();

      setOrderSuccess(data.StatusCode === 200);
      setSubmitting(false);
    } catch (error) {
      console.error("An error occurred while confirming the order", error);
      return "";
    }
  };

  async function checkPromoCode() {
    setPromoButtonClicked(true);
    const url =
      `${servercall}check-promocode?` +
      new URLSearchParams({
        promoCodeString: orderForm.promoCode,
      });
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      if (data) {
        setPromo(data.isPromoCodeValid);
      }
    } catch (error) {
      console.error("An error occurred while checking promo code", error);
      return "";
    }
  }

  async function checkPrice() {
    try {
      const response = await fetch(`${servercall}check-price`, {
        method: "GET",
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("An error occurred while checking promo code", error);
      return "";
    }
  }

  async function checkSales() {
    try {
      const response = await fetch(`${servercall}sales-active`, {
        method: "GET",
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("An error occurred while checking promo code", error);
      return "";
    }
  }

  function hoveringCopy(actionType) {
    setActionCopy(actionType);

    if (actionType === "click") {
      copyToClipboard();
    }
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(wallet);
  }

  return (
    <div
      className={`fixed max-w-[37rem] z-[10] right-[2rem] top-[10vh] max-h-[75vh] bg-white px-[2rem] py-[1rem] 2xl:max-w-[39rem] 2xl:p-4 z-10 rounded-[1.2rem] ${
        trait || promo || salesPerc ? "h-full" : "h-[75vh]"
      }`}
    >
      <div className="h-full flex flex-col">
        <div className="flex items-center justify-center pb-2 2xl:pb-3">
          <h3 className="font-bold text-[1.6rem]">Shipping Information</h3>
        </div>
        <form onSubmit={handleSubmit} className="text-md grow flex flex-col">
          <div className="grid grid-cols-2 gap-x-2 gap-y-[.4rem]">
            <div className="flex flex-col col-span-2">
              <input
                type="text"
                name="collector"
                onChange={handleChange}
                placeholder="Collector Profile Link*"
                className={`rounded-[.8rem] border border-[#cfcfcf] px-[.8rem] py-[.2rem] 2xl:py-[.4rem] text-[1.4rem] outline-0 ${
                  errors.collector
                    ? "placeholder:text-red-600"
                    : "placeholder:text-[#cfcfcf]"
                }`}
              />
            </div>
            <div className="flex flex-col col-span-2">
              <input
                type="text"
                name="email"
                onChange={handleChange}
                placeholder="Email*"
                className={`rounded-[.8rem] border border-[#cfcfcf] px-[.8rem] py-[.2rem] 2xl:py-[.4rem] text-[1.4rem] outline-0 ${
                  errors.email
                    ? "placeholder:text-red-600"
                    : "placeholder:text-[#cfcfcf]"
                }`}
              />
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                name="firstName"
                onChange={handleChange}
                placeholder="First Name*"
                className={`rounded-[.8rem] border border-[#cfcfcf] px-[.8rem] py-[.2rem] 2xl:py-[.4rem] text-[1.4rem] outline-0 ${
                  errors.firstName
                    ? "placeholder:text-red-600"
                    : "placeholder:text-[#cfcfcf]"
                }`}
              />
            </div>
            <div className="flex flex-col ">
              <input
                type="text"
                name="lastName"
                onChange={handleChange}
                placeholder="Last Name*"
                className={`rounded-[.8rem] border border-[#cfcfcf] px-[.8rem] py-[.2rem] 2xl:py-[.4rem] text-[1.4rem] outline-0 ${
                  errors.lastName
                    ? "placeholder:text-red-600"
                    : "placeholder:text-[#cfcfcf]"
                }`}
              />
            </div>
            <div className="flex flex-col col-span-2">
              <input
                type="text"
                name="address"
                onChange={handleChange}
                placeholder="Address Line*"
                className={`rounded-[.8rem] border border-[#cfcfcf] px-[.8rem] py-[.2rem] 2xl:py-[.4rem] text-[1.4rem] outline-0 ${
                  errors.address
                    ? "placeholder:text-red-600"
                    : "placeholder:text-[#cfcfcf]"
                }`}
              />
            </div>
            <input
              type="text"
              name="company"
              onChange={handleChange}
              placeholder="Add Company, C/O, Apt, Suite, Unit"
              className={`rounded-[.8rem] border border-[#cfcfcf] px-[.8rem] py-[.2rem] 2xl:py-[.4rem] col-span-2 text-[1.4rem] outline-0 ${
                errors.company
                  ? "placeholder:text-red-600"
                  : "placeholder:text-[#cfcfcf]"
              }`}
            />
            <div className="flex flex-col">
              <input
                type="text"
                name="town"
                onChange={handleChange}
                placeholder="Town/City*"
                className={`rounded-[.8rem] border border-[#cfcfcf] px-[.8rem] py-[.2rem] 2xl:py-[.4rem] text-[1.4rem] outline-0 ${
                  errors.town
                    ? "placeholder:text-red-600"
                    : "placeholder:text-[#cfcfcf]"
                }`}
              />
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                name="postcode"
                onChange={handleChange}
                placeholder="Postcode*"
                className={`rounded-[.8rem] border border-[#cfcfcf] px-[.8rem] py-[.2rem] 2xl:py-[.4rem] text-[1.4rem] outline-0 ${
                  errors.postcode
                    ? "placeholder:text-red-600"
                    : "placeholder:text-[#cfcfcf]"
                }`}
              />
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                name="country"
                onChange={handleChange}
                placeholder="Country/Region*"
                className={`rounded-[.8rem] border border-[#cfcfcf] px-[.8rem] py-[.2rem] 2xl:py-[.4rem] text-[1.4rem] outline-0 ${
                  errors.country
                    ? "placeholder:text-red-600"
                    : "placeholder:text-[#cfcfcf]"
                }`}
              />
            </div>
            <div className="flex flex-col">
              <input
                type="tel"
                name="phone"
                onChange={handleChange}
                placeholder="Phone number*"
                className={`rounded-[.8rem] border border-[#cfcfcf] px-[.8rem] py-[.2rem] 2xl:py-[.4rem] text-[1.4rem] outline-0 ${
                  errors.phone
                    ? "text-red-600 placeholder:text-red-600"
                    : "placeholder:text-[#cfcfcf]"
                }`}
              />
            </div>
          </div>
          <div
            className={`col-span-2 flex flex-col px-2 pt-2 2xl:pt-2 justify-between grow font-light`}
          >
            <div className="flex flex-col text-[1.6rem] leading-[2.4rem] 2xl:text-lg 2xl:space-y-1 2xl:leading-[2rem]">
              <div
                className={`flex flex-col justify-between mt-[.8rem] leading-[1.6rem] text-kobo-13 2xl:text-kobo-15 2xl:leading-[2rem]`}
              >
                <p className="font-primary font-semibold">
                  Garden Deck, Shipping & Wall Mount
                </p>
                <div className="flex flex-row items-center justify-between">
                  <p className="flex flex-row items-center">
                    {basePricing.eth}
                    {" ETH"}
                    {/* <img
                      src={ethSymbol}
                      alt="Etherium symbol"
                      className="pl-1 w-5 h-5 mb-1"
                    ></img> */}
                  </p>
                  <p className="flex flex-row items-center">
                    {basePricing.anime}
                    {" ANIME"}
                  </p>
                  <p className="flex flex-row items-center">
                    {basePricing.usdc}
                    {" USDC"}
                  </p>
                </div>
              </div>
              {/* <div className="flex flex-row justify-between items-center">
                <p className="text-nowrap align-baseline">Promo Code</p>
                <input
                  className={`border-b-2 border-gray-600 outline-0 w-40 px-2 ${
                    !promo && promoButtonClicked ? "border-red-400" : ""
                  }`}
                  type="text"
                  name="promoCode"
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="border-2 border-[#cfcfcf] rounded-full px-2"
                  onClick={checkPromoCode}
                >
                  Apply
                </button>
              </div> */}
              {trait && !salesPerc && (
                <div className="flex flex-row justify-between">
                  <p>Skateboard Trait Discount</p>
                  <p>-10%</p>
                </div>
              )}
              {salesPerc > 0 && (
                <div className="flex flex-row justify-between">
                  <p>{saleName}</p>
                  <p>-{salesPerc}%</p>
                </div>
              )}
              {!!(trait || promo || salesPerc) && (
                <div className="flex flex-row justify-between">
                  {/* <p>TOTAL</p> */}
                  <p className="flex flex-row">
                    {parseFloat(totalPricing.eth).toFixed(4)}
                    {" ETH"}
                    {/* <img
                      src={ethSymbol}
                      alt="Etherium symbol"
                      className="pl-1 w-5 h-5"
                    ></img> */}
                  </p>
                  <p>
                    {parseFloat(totalPricing.anime).toFixed(4)}
                    {" ANIME"}
                  </p>
                  <p>
                    {parseFloat(totalPricing.usdc).toFixed(4)}
                    {" USDC"}
                  </p>
                </div>
              )}
            </div>

            <div className="flex flex-col pt-2">
              <p className="font-primary font-semibold leading-[1.6rem] text-kobo-13 2xl:text-kobo-15">
                Send any of the above to this address:{" "}
                <span className="block font-light">(Any EVM Network)</span>
              </p>
              <p className="mt-2 text-[1.2rem] 2xl:text-kobo-13 flex flex-row items-center">
                {wallet}
                <span className="ml-2 w-[1.2rem] h-[1.2rem] 2xl:w-[2rem] 2xl:h-[2rem]">
                  {actionCopy === "leave" && (
                    <img
                      src={copy_default}
                      className="cursor-pointer w-[1.2rem] h-[1.2rem] 2xl:w-[2rem] 2xl:h-[2rem] hover:hidden"
                      alt="copy element"
                      onMouseEnter={() => hoveringCopy("hover")}
                    ></img>
                  )}
                  {actionCopy === "hover" && (
                    <img
                      src={copy_hover}
                      className="cursor-pointer w-[1.2rem] h-[1.2rem] 2xl:w-[2rem] 2xl:h-[2rem]"
                      alt="copy element hover"
                      onMouseLeave={() => hoveringCopy("leave")}
                      onMouseDown={() => hoveringCopy("click")}
                    ></img>
                  )}
                  {actionCopy === "click" && (
                    <img
                      src={copy_click}
                      className="cursor-pointer w-[1.2rem] h-[1.2rem] 2xl:w-[2rem] 2xl:h-[2rem]"
                      alt="copy element click"
                      onMouseLeave={() => hoveringCopy("leave")}
                    ></img>
                  )}
                </span>
              </p>
            </div>

            <p className="mt-2 text-kobo-13 2xl:text-kobo-15">
              <a
                className="underline"
                href="https://twitter.com/messages/compose?recipient_id=1711767105664581632"
                target="_blank"
                rel="noreferrer"
              >
                Contact us
              </a>{" "}
              for a different payment method
            </p>

            <div className="flex flex-col mt-3">
              <label
                className={`text-kobo-13 leading-[1.8rem] 2xl:text-kobo-15 leading-4 ${
                  errors.transactionId ? "text-red-600" : ""
                }`}
              >
                Transaction ID
              </label>
              <input
                className="border-b-[1px] border-gray-600 outline-0 text-kobo-15"
                type="text"
                name="transactionId"
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col py-3">
              <div className="flex flex-row items-start py-0.5 2xl:py-1">
                <input
                  name="disclaimer"
                  id="disclaimer"
                  type="checkbox"
                  className="cursor-pointer mt-0.5 2xl:mt-1"
                  onChange={handleChange}
                />
                <label
                  htmlFor="disclaimer"
                  className={`text-[1.1rem] 2xl:text-[1.25rem] text-start pl-[.5rem] ${
                    errors.disclaimer ? "text-red-600" : ""
                  }`}
                >
                  I have reviewed the product information, shipping details,
                  <br></br>and disclaimer prior to finalizing my order.
                </label>
              </div>
            </div>

            <div className="col-span-2 flex justify-center align-center h-[3.2rem]">
              <button
                disabled={orderSuccess}
                type="submit"
                className={`border rounded-full py-[.4rem] px-[2rem] text-kobo-13 font-primary font-semibold
                ${submitting ? "cursor-default" : "cursor-pointer"}
                ${
                  orderSuccess
                    ? "text-white bg-kobo-o1 border-kobo-o2"
                    : "border-[#cfcfcf]"
                }`}
              >
                {orderSuccess ? "Order Sent" : "Send"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
