import { useEffect, useState } from "react";
import detailStyle from "../styles/pages/ProductDetail.module.css";
import backIcon from "../asset/menu/Icon_back.svg";
import { Link } from "react-router-dom";

const ProductDetail = ({ product, addToCart }) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedSize, setSelectedSize] = useState(product.size);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setSelectedImage((prev) => {
        if (prev === product.images.length - 1) return 0;
        return selectedImage + 1;
      });
    } else if (isRightSwipe) {
      setSelectedImage((prev) => {
        if (prev === 0) return product.images.length - 1;
        return selectedImage - 1;
      });
    }
  };

  const handleDecrease = () => {
    if (selectedQuantity > 1) {
      setSelectedQuantity(selectedQuantity - 1);
    }
  };

  const handleIncrease = () => {
    setSelectedQuantity(selectedQuantity + 1);
  };

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value >= 1) {
      setSelectedQuantity(value);
    } else if (value < 1) {
      setSelectedQuantity(1);
    }
  };

  const handleProductAddToCart = (e) => {
    const button = e.target;
    e.target.focus();
    setTimeout(() => button.blur(), 1000);

    addToCart({
      ...product,
      quantity: selectedQuantity,
      size: selectedSize,
    });
  };

  // Change image over time
  function nextImage() {
    let idx = selectedImage + 1;

    if (idx >= product.images.length) {
      idx = 0;
    }

    setSelectedImage(idx);
  }

  let imageTimer = null;
  useEffect(() => {
    imageTimer = setTimeout(nextImage, 5000);

    return () => {
      clearTimeout(imageTimer);
    };
  }, [selectedImage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={detailStyle.detail__productDetail}>
      <div className={detailStyle.detail__productDetailLeft}>
        <Link to="/shop" className="absolute top-0 left-[-3rem] z-10">
          <img
            src={backIcon}
            alt="Back Icon"
            className="w-[1.7rem] h-[1.7rem] cursor-pointer z-10 transition-none"
          />
        </Link>
        <div
          className={detailStyle.detail__mainImageContainer}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <img
            src={
              process.env.REACT_APP_SERVER_BASE_URL +
              product.images[selectedImage]
            }
            alt={product.name}
            className={detailStyle.detail__mainImage}
          />
        </div>
        <div className={detailStyle.detail__circleContainer}>
          {product.images.map((img, index) => (
            <div
              key={index}
              className={`${detailStyle.detail__circle} ${
                selectedImage === index ? detailStyle.detail__circleActive : ""
              }`}
              onClick={() => setSelectedImage(index)}
            ></div>
          ))}
        </div>
      </div>
      <div className={detailStyle.detail__productDetailRight}>
        <div className={detailStyle.detail__productDetailInfo}>
          <div className={detailStyle.detail__productDetailInfoWrapper}>
            <div className={detailStyle.detail__productDetailInfoHeader}>
              <h1 className={detailStyle.detail__productTitle}>
                {product.name}
              </h1>
              <p className={detailStyle.detail__productPrice}>
                ${product.price}
              </p>
              {product.subtitle && (
                <p className={detailStyle.detail__productSubtitle}>
                  {product.subtitle}
                </p>
              )}
            </div>
            <div className={detailStyle.detail__productDetailInfoActions}>
              <div
                className={detailStyle.detail__productDetailInfoActionsQuantity}
              >
                <label htmlFor="quantity">Quantity</label>
                <div
                  className={
                    (detailStyle.detail__quantityInput,
                    detailStyle.detail__selection)
                  }
                >
                  <button
                    className={detailStyle.detail__quantityButton}
                    onClick={handleDecrease}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    id="quantity"
                    value={selectedQuantity}
                    onChange={handleChange}
                    min={1}
                  />
                  <button
                    className={detailStyle.detail__quantityButton}
                    onClick={handleIncrease}
                  >
                    +
                  </button>
                </div>
              </div>
              {product.slug === "garden_deck" ? (
                <Link
                  to={`/workshop`}
                  className={detailStyle.detail__productQuickBuyButton}
                >
                  Create
                </Link>
              ) : (
                <button
                  className={detailStyle.detail__productQuickBuyButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(product.stripePaymentLink, "_blank");
                  }}
                >
                  Quick buy
                </button>
              )}
              <button
                className={detailStyle.detail__productAddToCart}
                onClick={(e) => handleProductAddToCart(e)}
              ></button>
            </div>
            <pre className={detailStyle.detail__productDescription}>
              {product.description}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
