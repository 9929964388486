import React, { useEffect, useState } from "react";

import shopStyle from "../styles/pages/Shop.module.css";
import detailStyle from "../styles/pages/ProductDetail.module.css";

import Topbar from "../components/Topbar";
import Footer3 from "../components/Footer3";

import { productImages } from "../data/products";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cart from "../components/Cart";

import closeIcon from "../asset/close_X.svg";
import MobileMenu from "../components/MobileMenu";
import ProductDetail from "./ProductDetail";

import { shop_termsOfService } from "../data/termsOfService";

const servercall = process.env.REACT_APP_SERVER_BASE_URL;

const Shop = ({ isMobileView }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);
  const [footerOpen, setFooterOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductKey, setSelectedProductKey] = useState(null);

  const [products, setProducts] = useState([]);

  // As the project grows, we can use a context to manage the cart state
  // at this moment, there is no redux
  const [cart, setCart] = useState([]); // {product, size, quantity}
  const [cartQuantity, setCartQuantity] = useState([]);

  const addProductToDB = async (datas) => {
    const {
      name,
      description,
      size,
      price,
      key: stripeProductId,
    } = {
      ...datas,
    };
    const product = {
      name,
      description,
      size,
      price,
      slug: selectedProductKey,
      stripeProductId,
    };

    try {
      const response = await fetch(`${servercall}product/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(product),
      });

      if (response.ok) {
        alert("Product added successfully!");
      } else {
        alert("Error adding product");
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  useEffect(() => {
    // Fetch the products from the server
    const prodRequest = async () => {
      const prod = await fetch(`${servercall}products`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const prodData = await prod.json();

      setProducts(prodData);

      // console.log("Products: ", prodData);
    };

    prodRequest();
  }, []);

  useEffect(() => {
    const quantity = cart.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);

    setCartQuantity(quantity);
  }, [cart]);

  useEffect(() => {
    if (id) {
      const product = products.find((prod) => prod.slug === id);
      if (product) {
        setSelectedProduct(product);
        setSelectedProductKey(product.slug);
        return;
      }
    } else {
      resetProduct();
    }

    navigate("/shop");
  }, [products, id]);

  const toggleCart = () => {
    // document.querySelector("#root").classList.toggle("no_scroll");

    setCartOpen(!cartOpen);
  };

  const toggleFooter = () => {
    setFooterOpen(!footerOpen);
  };

  const resetProduct = () => {
    setSelectedProduct(null);
    setHoveredIndex(-1);
  };

  const TopbarChildren = (
    <>
      {!isMobileView && (
        <button
          className="rounded-[2.4rem] w-x10 h-34 bg-white border border-gray-200 text-kobo-16"
          onClick={toggleFooter}
        >
          Terms of Service
        </button>
      )}
      <button
        className={`rounded-[2.4rem] w-34 h-34 border text-white z-[99] bg-kobo-o1 border-kobo-o2 text-kobo-16`}
        onClick={toggleCart}
      >
        {cartOpen ? (
          <img
            src={closeIcon}
            alt="close icon"
            className="w-6 h-6 cursor-pointer m-auto"
          />
        ) : (
          cartQuantity
        )}
      </button>
    </>
  );

  const quickAddToCart = (productKey) => {
    const existingProduct = cart.find(
      (item) => item.product.name === products[productKey].name
    );
    if (existingProduct) {
      existingProduct.quantity += 1;
      setCart([...cart]);
    } else {
      const newItem = {
        image:
          process.env.REACT_APP_SERVER_BASE_URL + products[productKey].imageURL,
        product: products[productKey],
        size: products[productKey].size,
        quantity: 1,
        prod_key: productKey,
      };
      setCart([...cart, newItem]);
    }
  };

  const handleAddToCart = (product) => {
    const existingProduct = cart.find(
      (item) => item.product.name === product.name && item.size === product.size
    );
    if (existingProduct) {
      existingProduct.quantity += product.quantity;
      setCart([...cart]);
    } else {
      const newItem = {
        // productImages[selectedProductKey][0],
        image: process.env.REACT_APP_SERVER_BASE_URL + product.imageURL,
        product: product,
        size: product.size,
        quantity: product.quantity,
        prod_key: selectedProductKey,
      };
      setCart([...cart, newItem]);
    }
  };

  const handleRemoveFromCart = (productToRemove) => {
    const newCart = cart.filter(
      (item) =>
        item.product.name !== productToRemove.product.name ||
        item.size !== productToRemove.size
    );
    setCart(newCart);
  };

  return (
    <section className={shopStyle.shop}>
      <Topbar
        isMobileView={isMobileView}
        hamburgerClicked={() => setMenuOpen(!menuOpen)}
        showNavigationLinks={!isMobileView}
        childrenEnd={TopbarChildren}
        customCallback={() => resetProduct()}
      />

      {isMobileView && (
        <MobileMenu
          open={menuOpen}
          closeMobileMenu={() => setMenuOpen(!menuOpen)}
        />
      )}

      {!selectedProduct && !(isMobileView && cartOpen) && (
        <div className={shopStyle.shop__products}>
          {products.map((product, index) => (
            <div
              key={index}
              className={shopStyle.shop__product}
              onClick={
                isMobileView
                  ? () => {
                      setHoveredIndex(index);
                    }
                  : null
              }
              onMouseOver={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
            >
              {/* productImages[product.slug][0] */}
              <img
                src={process.env.REACT_APP_SERVER_BASE_URL + product.imageURL}
                alt={product.name}
                style={
                  isMobileView && hoveredIndex === index
                    ? { filter: "brightness(0.3)" }
                    : {}
                }
              />
              {hoveredIndex === index && (
                <div className={shopStyle.shop__productInfoContainer}>
                  <div className={shopStyle.shop__productInfo}>
                    <p className={shopStyle.shop__productName}>
                      {product.name}
                    </p>
                    <p className={shopStyle.shop__productPrice}>
                      ${product.price}
                    </p>
                  </div>
                  <div className={shopStyle.shop__productActions}>
                    <Link
                      to={`/shop/${product.slug}`}
                      className={shopStyle.shop__productActionButton}
                      onClick={() => {
                        setSelectedProduct(product);
                        setSelectedProductKey(product.slug);
                        // addProductToDB(products[productKey]);
                      }}
                    >
                      Details
                    </Link>
                    {/* <button
                      className={shopStyle.shop__productActionButton}
                      onClick={(e) => {
                        updateProductInDB(product);
                      }}
                    >
                      UPDATE ME
                    </button> */}
                    {product.slug === "garden_deck" ? (
                      <Link
                        to={`/workshop`}
                        className={shopStyle.shop__productActionButton}
                      >
                        Create
                      </Link>
                    ) : (
                      <button
                        className={shopStyle.shop__productActionButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(product.stripePaymentLink, "_blank");
                        }}
                      >
                        Quick Buy
                      </button>
                    )}
                    <button
                      className={shopStyle.shop__productActionButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.target.focus();
                        setTimeout(() => e.target.blur(), 200);
                        quickAddToCart(index);
                      }}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {selectedProduct && !(isMobileView && cartOpen) && (
        <ProductDetail
          product={selectedProduct}
          // productImages={productImages[selectedProductKey]}
          addToCart={handleAddToCart}
        />
      )}
      {
        <div className={shopStyle.shop__cartPosition}>
          <Cart
            cartProducts={cart}
            visible={cartOpen}
            removeItem={handleRemoveFromCart}
            isMobileView={isMobileView}
          ></Cart>
        </div>
      }
      {!isMobileView && (
        <Footer3
          tos={shop_termsOfService}
          isOpen={footerOpen}
          toggleFooter={toggleFooter}
        />
      )}
    </section>
  );
};

export default Shop;
