import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./global-style.css";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

// prevent pitch zoom on mobile
document.addEventListener("gesturestart", function (e) {
  e.preventDefault();
  document.body.style.zoom = 1;
});

document.addEventListener("gesturechange", function (e) {
  e.preventDefault();
  document.body.style.zoom = 1;
});
document.addEventListener("gestureend", function (e) {
  e.preventDefault();
  document.body.style.zoom = 1;
});

// when the keyboard is hidden in iOS scrolls the window to top
// this fixes the problem of having the skateboard moved to top
// after the input field was filled
// window.addEventListener("resize", function (e) {
//   e.preventDefault();
//   window.scrollTo(0, 0);
//   window.scroll(0, 0);
//   document.body.scrollTop = 0;
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
